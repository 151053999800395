<template>
    <div class="max-w-xl mx-auto sm:px-6 lg:px-8" v-if="!loading">
        <div class="py-8 px-4 sm:mx-auto sm:w-full" v-if="!available">
            <h1 class="text-center text-2xl leading-8 font-extrabold tracking-tight title-content-container sm:text-3xl sm:leading-10">
                {{ $t('tables.area.table-selection.unavailable') }}
            </h1>
        </div>
        <div class="content-container checkout-box radius-anchor" v-if="available">
            <div class="mb-5">
                <h1 class="text-center text-2xl leading-8 font-extrabold tracking-tight title-content-container sm:text-3xl sm:leading-10 mb-2">
                    {{ area.attributes.name }}
                </h1>
                <h3 class="text-center text-xl leading-8 font-normal tracking-tight title-content-container sm:text-2xl sm:leading-10">
                    <span v-if="this.searchtype == 'rowseat'">{{ $t('tables.area.rowseat-selection.subtitle') }}</span>
                    <span v-else>{{ $t('tables.area.table-selection.subtitle') }}</span>
                </h3>
            </div>

            <div v-if="notfound" class="mt-1 rounded-sm p-4 mb-5 alert-danger text-center">

                <span v-if="this.searchtype == 'rowseat'">
                    {{ $t('tables.area.rowseat-selection.notfound') }}
                </span>
                <span v-else>
                    {{ $t('tables.area.table-selection.notfound') }}
                </span>
            </div>

            <div v-if="this.searchtype == 'rowseat'">
                <span class="flex justify-center text-lg mb-4 font-bold">{{ $t('tables.selectrow') }}</span>
                <div class="flex justify-center mb-10">
                    <div v-for="index in [0, 1]" :key="index" class="col w-20 mx-3">
                        <button @click="updateRowSeat('row', index, 1)" class="flex justify-center mx-auto plus-minus-button-row-seat">
                            <span>+</span>
                        </button>
                        <div class="px-3 py-2text-center text-2xl leading-8 font-extrabold tracking-tight title-content-container sm:text-3xl sm:leading-10 radius-anchor border">
                            <input readonly @change="rowAdd(index)" :value="row.charAt(index)" class="w-full text-center" style="font-size: 25px !important">
                        </div>
                        <button @click="updateRowSeat('row', index, -1)" class="flex justify-center mx-auto plus-minus-button-row-seat">
                            <span>-</span>
                        </button>
                    </div>
                </div>
                <span class=" flex justify-center text-lg mb-4 font-bold">{{ $t('tables.selectseat') }}</span>
                <div class="flex justify-center mb-10">
                    <div v-for="index in [0, 1, 2]" :key="index" class="col w-20 mx-3">
                        <button @click="updateRowSeat('seat', index, 1)" class="flex justify-center mx-auto plus-minus-button-row-seat">
                            <span>+</span>
                        </button>
                        <div class="px-3 py-2text-center text-2xl leading-8 font-extrabold tracking-tight title-content-container sm:text-3xl sm:leading-10 radius-anchor border">
                            <input readonly v-on:input="setValue(index, 'seat')" :value="seat.charAt(index)" class="w-full text-center" style="font-size: 25px !important">
                        </div>
                        <button @click="updateRowSeat('seat', index, -1)" class="flex justify-center mx-auto plus-minus-button-row-seat">
                            <span>-</span>
                        </button>
                    </div>
                </div>
            </div>

            <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }">
                <div class="mb-3 mb-0">
                    <div v-if="this.searchtype == 'tablenumber'">
                        <Field type="text" name="tablenumber" :placeholder="$t('fields.tablenumber')" class="py-3 px-2 block w-full shadow-sm rounded-sm focus:outline-none focus:ring-transparent" :class="{'border-red-500': errors.tablenumber}" />
                    </div>
                </div>
                <button type="submit" class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                    {{ $t('button.confirm') }}
                    <svg v-if="submitting" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" stroke="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z" clip-rule="evenodd"/>
                    </svg>
                </button>
            </Form>
        </div>
    </div>
</template>

<script>
import * as _api from '../../lib/api';
import * as _state from "../../lib/state";

import { Field, Form } from 'vee-validate'
import * as yup from "yup"

export default {
    name: 'AreaSelection',
    props: ['location_slug'],
    components: {
        Field,
        Form,
    },
    data () {
        return {
            validationRules: {},
            tablenumber: '',
            loading: true,
            submitting: false,
            available: null,
            area: null,
            notfound: false,
            searchtype: null,
            row: '00',
            seat: '000',
        }
    },
    mounted() {
        this.getTable().finally(() => {
            this.loading = false
        });

        if (this.searchtype === 'tablenumber') {
            this.validationRules = yup.object().shape({
                tablenumber: yup.string().required().max(255),
            });
        } else {
            this.validationRules = yup.object().shape({
                row: yup.string().required().max(255),
                seat: yup.string().required().max(255),
            });
        }
    },

    methods: {
        async getTable() {
            this.area = await _api.get('location/areas/:area_id/tables-selection', this.$route.params.area_id);

            if (this.area) {
                this.available = true;
                this.searchtype = this.area.attributes.tables_search_method;
                return;
            }

            this.available = false;
        },

        updateRowSeat(type, index, valueChange) {

            // get the new value
            let value = parseInt(this[type].charAt(index)) + valueChange;

            // reset to 0 when large then 9
            if (value > 9) {
                value = 0;
            }
            else if (value < 0) {
                value = 9;
            }

            // combine string
            this[type] = this[type].substr(0, index) + value + this[type].substr(index + 1);
        },

        async submit(values) {
            this.notfound = false
            this.submitting = true

            let request = {
                searchtype: this.searchtype
            };

            if (this.searchtype === 'rowseat') {
                request.row = parseInt(this.row);
                request.seat = parseInt(this.seat);
            } else {
                request.tablenumber = values.tablenumber;
            }

            const response = await _api.post('location/areas/:area_id/tables-selection', request, this.$route.params.area_id);

            if (response.result === 'success') {
                this.$router.push('/' + this.location_slug + '/tafel/' + response.data.id);
                return;
            }

            this.notfound = true
            this.submitting = false;
        },
    },
	computed: {

        location () {
            return _state.get('location/getLocation');
        },
    }
}
</script>
